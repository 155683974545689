








import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CouponInventory: () => import('@/components/Coupons/CouponInventory.vue'),
    CouponTutorial: () => import('@/components/Coupons/CouponTutorial.vue'),
    RedeemCoupon: () => import('@/components/Coupons/RedeemCoupon.vue')
  },
})
export default class Coupons extends Vue {}
